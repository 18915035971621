<template>
  <div class="wrapper">
    <div class="viewer" ref="imagePreview"></div>
    <div class="d-flex justify-content-center mt-4">
      <icon-button
        @click="handlePrevious"
        v-if="hasMultipleImages && imageIdx > 0"
        class="btn-outline-primary mr-2"
        icon="arrow-left"
      >
      </icon-button>

      <icon-button
        @click="handleNext"
        v-if="hasMultipleImages && imageIdx < imageCount - 1"
        class="btn-outline-primary ml-2"
        icon="arrow-right"
      >
      </icon-button>
    </div>
  </div>
</template>

<script>
import Dynamsoft from "dwt";
import { WEB_TWAIN_ID } from "@/modules/constants";
import IconButton from "@/components/common/IconButton.vue";

export default {
  name: "TwainImageViewer",
  components: { IconButton },
  data() {
    return {
      viewer: null,
      hasMultipleImages: false,
      imageIdx: 0,
      imageCount: 0
    };
  },

  mounted() {
    this.start();
  },
  methods: {
    handlePrevious() {
      const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
      this.imageIdx = DWObject.Viewer.previous();
    },
    handleNext() {
      const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
      this.imageIdx = DWObject.Viewer.next();
    },
    async getImageData(imagePath) {
      const imageFetch = await fetch(imagePath);
      const imageBlob = await imageFetch.blob();

      return imageBlob;
    },
    start() {
      const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
      if (DWObject) {
        const element = this.$refs.imagePreview;
        DWObject.Viewer.bind(element);
        DWObject.Viewer.width = 800;
        DWObject.Viewer.height = 800;
        DWObject.Viewer.singlePageMode = true;
        DWObject.Viewer.show();
        this.hasMultipleImages = DWObject.HowManyImagesInBuffer > 1;
        this.imageCount = DWObject.HowManyImagesInBuffer;
        this.viewer = DWObject.Viewer;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.viewer {
}
</style>
