<template>
  <container>
    <div class="body p-3 mx-3">
      <h1>Upload Req Forms</h1>
      <input
        class="m-2"
        accept="application/pdf"
        type="file"
        id="img"
        ref="fileInput"
        multiple
        @input="onSelectFile"
      />
      <div v-if="fileNames.length" class="file-names mx-2 mb-2">
        <div v-for="file of fileNames" v-bind:key="file">{{ file }}</div>
      </div>
      <SelectInput
        class="select-input"
        label="Image Type"
        v-model="reqImageTypeId"
        :items="imageTypes"
      />
      <div>
        <button class="btn btn-primary" @click="handleUpload">Upload Files</button>
      </div>
      <div v-if="totalImages">Uploaded {{ uploadedImages }} of {{ totalImages }} images</div>
      <div v-if="sourceList.length" class="mt-3">
        <h1>Scan Req Forms</h1>
        <SelectInput
          label="Twain Device"
          class="select-input"
          :items="sourceList"
          displayExpr="displayName"
          valueExpr="id"
          name="twainDevice"
          v-model="selectedSource"
        />
        <SelectInput
          label="Image Resolution"
          class="select-input"
          :items="resolutions"
          displayExpr="displayName"
          valueExpr="id"
          name="resolution"
          v-model="selectedResolution"
        />
        <SelectInput
          class="select-input"
          label="Image Type"
          v-model="reqImageTypeId"
          :items="imageTypes"
        />
        <SelectInput
          class="select-input"
          label="Use Feeder"
          :items="booleanOptions"
          v-model="useScannerFeeder"
        />
        <div class="d-flex justify-content-between">
          <button v-if="sourceList.length" class="btn btn-success ml-2" @click="startScan">
            Start Scan
          </button>
          <!-- <button v-if="imagesInBuffer" class="btn btn-primary ml-2" @click="handleScannedImages">
            Submit
          </button>

          <button v-if="imagesInBuffer" @click="showViewer" type="button" class="btn btn-secondary">
            Open Viewer ({{ imagesInBuffer }})
          </button> -->
        </div>
        <div v-if="scannedReqImages.length">
          <h3 class="text-center">
            {{ scannedReqCounts.cases }} Cases, {{ scannedReqCounts.images }} Images
          </h3>
          <DxGridWithSearch
            :dataSource="dataSource"
            :columns="columns"
            :toolbar="toolbar"
            @initialized="initGrid"
          >
            <template v-slot:caseNumber="{ data: { data } }">
              <div v-if="!data.caseId">
                <span class="text-danger">{{
                  data.errorMessage || "Unable to read case number."
                }}</span>
              </div>
              <div v-else>
                <router-link
                  v-if="permissions.CaseView"
                  :to="{
                    name: 'CaseView',
                    params: {
                      caseId: data.caseId
                    }
                  }"
                >
                  {{ data.caseNumber }}
                </router-link>
                <div v-else>{{ data.caseNumber }}</div>
              </div>
            </template>
            <template v-slot:actions="{ data: { data } }">
              <div class="d-flex align-items-center justify-content-center">
                <icon-button
                  v-tooltip.right.start="'View images'"
                  :disabled="!data.caseId && !getLocalImageUrl(data)"
                  @click="viewImages(data)"
                  class="ml-1 p-0 text-secondary pointer"
                  icon="eye"
                />
                <icon-button
                  v-if="permissions.CaseImageCreateEdit"
                  v-tooltip.right.start="'Delete images.'"
                  @click="deleteCaseImages(data)"
                  class="mx-1 p-0 text-danger pointer"
                  icon="trash-alt"
                />
              </div>
            </template>
          </DxGridWithSearch>
        </div>
      </div>
    </div>
    <modal :status="isViewerOpen" @close="isViewerOpen = false">
      <TwainImageViewer></TwainImageViewer>
    </modal>
  </container>
</template>

<script>
import { AuditLogApi, CasesApi, ImagesApi } from "@/services";
import Container from "./common/Container.vue";
import { createLogItem } from "@/modules/helpers";
import { AuditLogItems } from "@/modules/enums";
import SelectInput from "./common/SelectInput.vue";
import TWAINDriver from "@/modules/TWAINDriver";
import {
  SCANNED_ALL_IMAGE,
  // SCANNED_IMAGE,
  UPLOAD_PROGRESS,
  fromBusEvent
} from "@/modules/eventBus";
import { startWith, map, distinctUntilChanged, filter } from "rxjs/operators";
import Dynamsoft from "dwt";
import { WEB_TWAIN_ID } from "@/modules/constants";
import ArrayStore from "devextreme/data/array_store";
import { mapState, mapGetters } from "vuex";
import { handleErrors } from "@/modules/handleErrors";
import DxGridWithSearch from "@/components/common/DxGridWithSearch.vue";
import IconButton from "@/components/common/IconButton.vue";
import TwainImageViewer from "./common/TwainImageViewer.vue";
import Modal from "@/components/common/Modal";

export default {
  components: { Container, SelectInput, DxGridWithSearch, IconButton, TwainImageViewer, Modal },
  metaInfo: {
    title: "Upload Req Images",
    titleTemplate: "IntelliPath - %s"
  },
  data() {
    return {
      fileNames: [],
      totalImages: 0,
      isViewerOpen: false,
      uploadedImages: 0,
      sourceList: [],
      selectedSource: null,
      resolutions: [
        { id: 0, displayName: "Use Device" },
        { id: 1980, displayName: "1080 HD" },
        { id: 720, displayName: "720 HD" },
        { id: 480, displayName: "480 SD" }
      ],
      selectedResolution: 0,
      isLoading: false,
      DWObject: null,
      imageIndex: null,
      imageTypes: [],
      reqImageTypeId: null,
      imagesInBuffer: 0,
      booleanOptions: [
        { id: 1, displayName: "Yes" },
        { id: 0, displayName: "No" }
      ],
      useScannerFeeder: 1
    };
  },

  created() {
    if (!TWAINDriver.isLoaded && this.enableTWAINDriver) {
      TWAINDriver.load();
    }
  },
  mounted() {
    this.getReqImageType();
    if (this.enableTWAINDriver) {
      this.startTwain();
      this.$store.commit("applicationSettings/setUseScannerFeeder", true);
    }
  },
  subscriptions() {
    const uploadStatus$ = fromBusEvent(UPLOAD_PROGRESS).pipe(
      filter(() => this.isLoading),
      startWith({ loaded: 0, total: 100 }),
      map(progressEvent => Math.round((progressEvent.loaded * 100) / progressEvent.total)),
      distinctUntilChanged(),
      map(progress => ({
        width: `${progress}%`,
        progress: progress
      }))
    );
    const imageReceived$ = fromBusEvent(SCANNED_ALL_IMAGE).pipe(
      map(data => {
        const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
        const { imageId } = data;
        this.imageIndex = DWObject.ImageIDToIndex(imageId);
        this.imagesInBuffer = DWObject.HowManyImagesInBuffer;
      })
    );
    return {
      uploadStatus$,
      imageReceived$
    };
  },
  watch: {
    selectedSource(nv) {
      this.$store.commit("applicationSettings/setSelectedTWAINDevice", nv);
    },
    selectedResolution(nv) {
      this.$store.commit("applicationSettings/setSelectedResolution", nv);
    },
    sourceList: {
      deep: true,
      handler(nv) {
        if (nv?.length) {
          this.selectedSource = this.$store.state.applicationSettings.selectedTWAINDevice;
        }
      }
    },
    useScannerFeeder(nv) {
      this.$store.commit("applicationSettings/setUseScannerFeeder", nv);
    }
  },
  computed: {
    ...mapState({
      scannedReqImages: state => state.sessionDetails.scannedReqImages,
      scannedReqCounts: state => state.sessionDetails.scannedReqCounts,
      enableTWAINDriver: state => state.applicationSettings.enableTWAINDriver,
      localImageUrls: state => state.sessionDetails.localImageUrls
    }),
    ...mapGetters(["permissions"]),
    dataSource() {
      return new ArrayStore({
        data: this.scannedReqImages
      });
    },
    columns() {
      return [
        { caption: "Actions", type: "buttons", cellTemplate: "actions" },
        { dataField: "caseNumber", sortIndex: 0, cellTemplate: "caseNumber" },
        { dataField: "imageCount" },
        {
          dataField: "dateUploaded",
          caption: "Scanned On",
          dataType: "datetime"
        }
      ];
    },
    toolbar() {
      return {
        items: [
          {
            widget: "dxButton",
            options: {
              icon: "fa fa-trash-alt",
              onClick: () => this.handleDeleteSelected(),
              type: "danger"
            },
            visible: this.permissions.CaseImageCreateEdit
          }
        ]
      };
    }
  },
  methods: {
    showViewer() {
      this.isViewerOpen = true;
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = Array.from(input.files);
      this.fileNames = files.map(e => e.name);
    },
    async handleUpload() {
      if (!this.fileNames.length) {
        window.alert("Please select files to upload.");
        return;
      }
      this.resetCounter();
      const caseNumbers = this.fileNames.map(e => this.parseFileName(e));
      const casesData = await CasesApi.getCaseIdsByCaseNumber(caseNumbers);
      const input = this.$refs.fileInput;
      const files = Array.from(input.files);
      this.totalImages = files?.length;
      let successfulCaseNumbers = [];
      const timer = ms => new Promise(res => setTimeout(res, ms));
      for (const imageData of files) {
        const caseNumber = imageData.name.replace(".pdf", "");
        const fileCaseData = casesData.find(e => e.searchValue === this.parseFileName(caseNumber));
        if (fileCaseData?.caseId) {
          const imagePath = URL.createObjectURL(imageData);
          const formData = new FormData();
          const file = await fetch(imagePath).then(r => r.blob());
          formData.append(`file`, file);
          formData.append(
            "jsonPayload",
            JSON.stringify({
              tagIds: [],
              specimenId: null,
              keywords: "",
              printOnReport: false,
              comment: "",
              imageTypeId: this.reqImageTypeId,
              caseId: fileCaseData.caseId
            })
          );
          const response = await ImagesApi.addImage(formData);
          if (response) {
            this.uploadedImages++;
            successfulCaseNumbers.push(fileCaseData.caseNumber);
            await timer(500);
          } else {
            this.totalImages--;
          }
        } else {
          this.totalImages--;
        }
      }
      if (this.uploadedImages) {
        window.notify(`Successfully uploaded ${this.uploadedImages} req forms.`);
        const logItem = createLogItem({}, AuditLogItems.ChangeAccession);
        logItem.comments = `Uploaded req images to the following cases:\n${successfulCaseNumbers.join(
          "\n"
        )}`;
        AuditLogApi.insertLogMessage(logItem);
        this.resetCounter();
      }
    },
    resetCounter() {
      this.totalImages = 0;
      this.uploadedImages = 0;
    },
    parseFileName(fileName) {
      // This will probably eventually be replaced by a lab setting
      return fileName.replace(".pdf", "").replace(/_.+$/, "").replace("-", "");
    },
    initGrid({ component }) {
      this.grid = component;
    },
    async handleDeleteSelected() {
      const selectedCases = this.grid.getSelectedRowsData();
      if (!selectedCases?.length) {
        window.notify("Please select images to delete.", "warning");
        return null;
      }
      const confirm = await window.confirm(
        `Are you sure you want to delete images for ${selectedCases.length} cases?`
      );
      if (!confirm) {
        return;
      }
      let imageIds = [];
      for (const caseData of selectedCases) {
        imageIds = [...imageIds, ...caseData.imageIds];
      }
      try {
        this.isLoading = true;
        await ImagesApi.deleteImages(imageIds);
        window.notify("Images deleted.");
        const newCases = this.scannedReqImages.filter(
          e => !selectedCases.map(f => f.caseId).includes(e.caseId)
        );
        this.$store.commit("sessionDetails/setScannedReqImages", newCases);
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteCaseImages(data) {
      if (!data?.caseId) {
        this.$store.commit(
          "sessionDetails/setScannedReqImages",
          this.scannedReqImages.filter(e => e.fileName !== data.fileName)
        );
        return;
      }
      const confirm = await window.confirm(
        `Are you sure you want to delete ${data.imageIds.length} images for case ${data.caseNumber}?`
      );
      if (!confirm) {
        return;
      }
      try {
        this.isLoading = true;
        await ImagesApi.deleteImages(data.imageIds);
        window.notify(`Deleted images for case ${data.caseNumber}.`);
        this.$store.commit(
          "sessionDetails/setScannedReqImages",
          this.scannedReqImages.filter(e => e.caseId !== data.caseId)
        );
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    async startTwain() {
      const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
      if (DWObject) {
        await this.getSources();
      }
      if (Number.isInteger(this.selectedTWAINDevice)) {
        const findInList = this.sourceList.find(e => e.id === this.selectedTWAINDevice);
        if (findInList) {
          this.selectedSource = this.selectedTWAINDevice;
        }
      }
    },
    async startScan() {
      try {
        this.isLoading = true;
        const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
        DWObject.RemoveAllImages();
        await TWAINDriver.acquireImage(this.selectedSource);
        this.handleScannedImages();
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getImageData(imagePath) {
      const imageFetch = await fetch(imagePath);
      const imageBlob = await imageFetch.blob();

      return imageBlob;
    },
    convertToBlob(imageIndex) {
      return new Promise((resolve, reject) => {
        const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
        DWObject.ConvertToBlob(
          [imageIndex],
          Dynamsoft.DWT.EnumDWT_ImageType.IT_PNG,
          resolve,
          reject
        );
      });
    },
    saveFile(imageIndex) {
      return new Promise((resolve, reject) => {
        const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
        DWObject.SaveAsPNG("scannedImage.png", imageIndex, resolve, reject);
      });
    },
    async handleScannedImages() {
      try {
        this.isLoading = true;
        const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
        const imageCount = DWObject.HowManyImagesInBuffer;
        if (!imageCount) {
          window.alert("No images found to scan.");
          return;
        }
        const formData = new FormData();
        formData.append("imageTypeId", this.reqImageTypeId);
        let imageUrls = [];
        for (let imageIndex = 0; imageIndex < imageCount; imageIndex++) {
          const imageUrl = DWObject.GetImageURL(imageIndex, this.selectedResolution);
          const file = await this.getImageData(imageUrl);
          const fileName = `scan${imageIndex + 1}.png`;
          formData.append(`file`, file, fileName);
          imageUrls.push({ fileName, imageUrl });
        }
        await this.$store.dispatch("sessionDetails/addReqImageScans", { formData, imageUrls });
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getReqImageType() {
      this.imageTypes = await ImagesApi.getImageTypes();
      const reqImageType = this.imageTypes.find(e => e.displayName.toLowerCase().includes("req"));
      this.reqImageTypeId = reqImageType?.id || null;
    },
    viewImages(data) {
      let payload = data?.imageIds;
      if (!data?.caseId) {
        payload = this.getLocalImageUrl(data);
      }
      if (payload) {
        this.$store.dispatch("sessionDetails/useImageFrame", payload);
      } else {
        window.alert("Unable to load image.");
      }
    },
    async getSources() {
      const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
      let tryCount = 0;
      while (!DWObject.SourceCount && tryCount < 3) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        tryCount++;
      }
      const sources = DWObject.GetSourceNames();
      this.sourceList = sources.map((e, idx) => ({ displayName: e, id: idx }));
      if (Number.isInteger(this.selectedTWAINDevice)) {
        const findInList = DWObject.GetSourceNameItems(this.selectedTWAINDevice);
        if (findInList) {
          this.selectedSource = this.selectedTWAINDevice;
        }
      }
    },
    getLocalImageUrl({ fileName }) {
      if (fileName) {
        const urlData = this.localImageUrls.find(e => e.fileName === fileName);
        if (urlData?.imageUrl) {
          return urlData.imageUrl;
        }
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.file-names {
  width: 10rem;
  max-height: 50vh;
  overflow: scroll;
}
.select-input {
  max-width: 25rem;
}
</style>
